import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import LanguageContext from '../../context/LanguageContext';

const ModalNewOrder = (props) => {
    const { texts } = useContext(LanguageContext);
    const navigate = useNavigate();
    const { isVisible, order, close, navi } = props
    
    const _open = () => {
        document.getElementsByTagName("body")[0].style.overflow = "auto";
        navigate(navi);
    }
    return (
        <>
            {
                isVisible &&
                <div className="container">
                    <div className="ebcf_modal ">
                        <div className='wd-600 ebcf_modal-content bg_modal'>
                            {
                                order.response &&
                                <div className="tx-center color-lavel ft-family-pre pd-30" dangerouslySetInnerHTML={{ __html: order.response }} ></div>
                            }
                             <div className='table-add dp-flex justify-content-between'>
                                <button
                                    onClick={close}
                                    className='ft-size_12 bd-none bg-primary pd-8 bd-radius_10 cursor-pointer color-white'
                                >
                                    {texts.Close}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>

    )
}

export default ModalNewOrder