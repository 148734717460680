import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import SideBar from '../../components/SideBar'

const IndexOrders = () => {

    const belong = 'order-history';
    const navigate = useNavigate();
    const location = useLocation();
    const [className, setClass] = useState(location && location.pathname);
    const [count, setCount] = useState(0);
    const scroll = (num) => {
        document.getElementById('scrll-list').scrollLeft += num;
    }

    useEffect(() => {
        setClass(location && location.pathname)
    }, [location])

    const handleClass = (c) => {
        scroll(c.scroll)
        setCount(count + 1);
        navigate(c.path);
        setClass(c.className);
    }


    return (
        <>
            <SideBar
                left={() => scroll(-300)}
                right={() => scroll(300)}
                handleClass={(c) => handleClass(c)}
                belong={belong}
                className={className}
            />
            <Outlet context={{ count: count }} />
        </>
    )
}

export default IndexOrders