import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: {
        spin: false
    }
}

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        update_Spin: (state, action) => {
            state.loading.spin = action.payload
        }
    }
})

export const { update_Spin } = loadingSlice.actions

export default loadingSlice.reducer