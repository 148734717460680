import React, { useContext, useEffect, useState } from 'react';
import { GroupImei } from '../../store/storeReducers/GroupsReducer';
import { useSelector, useDispatch } from "react-redux";
import ModalData from '../../components/modals/Modaldata';
import LanguageContext from '../../context/LanguageContext';
import { totalServices } from '../../store/storeReducers/ServicesReducer';
import { get_services } from '../../functions/ServiceFunction';

const Homeservices = () => {
    const { texts } = useContext(LanguageContext);
    const dispatch = useDispatch();
    const allServices = useSelector((state) => state.services.totalService.services);
    const [services, setServices] = useState([]);
    const [isVisible, setVisible] = useState(false);
    const [serv, setServ] = useState({});

    useEffect(() => {
        get_Services();
    }, [])

    const get_Services = async (value) => {
        try {
            const service = {
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : ''
              };
            const res = await get_services(service);
            dispatch(totalServices(res.data.services));
            setServices(res.data.services);
        } catch (error) {
        }
    }

    const _open = (s) => {
        if (s) {
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
            setServ(s);
            setVisible(true);
        } else {
            setVisible(!isVisible);
            document.getElementsByTagName("body")[0].style.overflow = "auto";
        }

    }

    return (
        <div className='container' >
            <ModalData
                isVisible={isVisible}
                service={serv}
                onClose={() => _open()}
            />
             <table className="tb bg-white mg-left_auto mg-right_auto mg-bottom_20 mg-top_20 default_dg_table bd-radius_10 box-shadow_1">
                    <thead >
                        <tr >
                            <th className="default_dg_td tx-left default_dg_a_header col-md-10">{texts.ServiceName}</th>
                            <th className="default_dg_td tx-center default_dg_a_header">{texts.Time}</th>
                        </tr>
                    </thead>
                    <tbody >
                    {
                        allServices.map((service, index) => {
                            return (
                                <tr  key={index} onClick={() => _open(service)} className="dg_tr cursor-pointer ft-family_monospace">
                                    <td className="default_dg_td tx-left dg_nowrap bd-left_solid_f1 bd-right_solid_f1 white-space">{service.name}</td>
                                    <td className="default_dg_td tx-center dg_nowrap bd-left_solid_f1 bd-right_solid_f1 white-space">{service.time}</td>
                                </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
        </div>
    )
}

export default Homeservices