import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { get_service } from '../../../functions/ServiceFunction';
import { new_order } from '../../../functions/OrderFunction';
import ModalNewOrder from '../../../components/modals/ModalNewOrder';
import { update_Spin } from '../../../store/storeReducers/LoadingReducer';
import LanguageContext from '../../../context/LanguageContext';
import { get_services } from '../../../functions/ServiceFunction';
import { totalServices } from '../../../store/storeReducers/ServicesReducer';

const ImeiServices = () => {
    const { texts } = useContext(LanguageContext);
    const dispatch = useDispatch();
    const allServices = useSelector((state) => state.services.totalService.services);
    const spin = useSelector((state) => state.loading.loading.spin);
    const user = useSelector((state) => state.auth.auth.user);
    const [visible, setVisible] = useState(false);
    const [sendRequest, setRequest] = useState('');
    const [service, setService] = useState('');
    const [lists, setList] = useState('');
    const [error, setErr] = useState({ err: [] });
    const [options, setOpt] = useState({
        menu: false,
        burk: false,
        loading: false
    });
    const [info , setInfo] = useState({
        imei: '',
        comments: ''
    });

    useEffect(() => {
        if (allServices[0] == null) {
            get_Services();
            dispatch(update_Spin(true))
        }
    }, []);

    const get_Services = async (value) => {
        try {
            const service = {
                action: 'services',
                search: value && value.search ? value.search : '',
                page: value && value.page ? value.page : ''
              };
            const res = await get_services(service);
            dispatch(totalServices(res.data.services));
        } catch (error) {
        }
        dispatch(update_Spin(false));
    }

    const _onService = async (id) => {
        setList('');
        setService('');
        if (!id) {
            return setService();
        }
        try {
            const res = await get_service({ id});
            if (res.data.service) {
                setService(res.data.service);
            }
        } catch (error) {
        }
    }

    const _newImei = async () => {
        setErr('');
        setOpt({ ...options, loading: true });
        try {
            const order = {
                service: service.id,
                imei: info.imei,
                comments: info.comments
            }
            const res = await new_order(order);
            if(res.data.order){
                setRequest(res.data.order);
                _open('s');
              } else  if(res.data.alert){
                alert(res.data.alert);
              }
            //   if(res.data.orders){
            //     dispatch( getOrdersTotal(res.data.orders) );
            //   }
        } catch (err) {
            if (err.response.data) {
                if (err.response.data.err) {
                    setErr(err.response.data.err);
                }
                if (err.response.data.alert) {
                    alert(err.response.data.alert);
                }
            }
        }
        setOpt({ ...options, loading: false });
    }

    const _open = (s) => {
        if (s) {
            document.getElementsByTagName("body")[0].style.overflow = "hidden";
            setVisible(true);
        } else {
            setVisible(!visible);
            document.getElementsByTagName("body")[0].style.overflow = "auto";
        }
    }

    const handleList = () => {
        if (lists) {
            setList('')
        } else {
            setList('dropdown-list')
        }
    }

    const handleChange = (e) => {
        setInfo({
          ...info,
          [e.target.name]: e.target.value
        });
        setErr({
          ...error,
          [e.target.name]: ''
        })
      }
  
    return (
        <div className='profile-content'>
            {
                !spin &&
                <div>
                    <ModalNewOrder
                        isVisible={visible}
                        order={sendRequest}
                        close={() => _open()}
                        navi={'/historial-imei'}
                    />
                    <div className='container' >
                        <div className='bg-white mg-left_auto mg-right_auto mg-top_20 default_dg_table bd-radius_10 box-shadow_1 default_dg_td '>
                            <div className="pst-relative">
                                <div className={`${lists} dropdown-simple select-none`}>
                                    <a onClick={() => handleList()}>
                                        <span>
                                            {service ? service.name  : texts.SelectService}
                                        </span>
                                    </a>
                                    <div className='dropdown'>
                                        <div className='search-list'>
                                            <input
                                                onChange={(e) => get_Services({ search: e.target.value })}
                                                autoComplete='new-password'
                                                className='search-input'
                                            />
                                        </div>
                                        <ul className='results-dropdown'>
                                            {
                                                <li className='group-list'>
                                                    <span>
                                                        {'Servicios de verificacion'}
                                                    </span>
                                                    {
                                                        allServices.map((s, k) => {
                                                            return (
                                                                <ul
                                                                    className='pd-0' key={k} value={s.id} >
                                                                    {
                                                                        <li
                                                                            onClick={(e) => _onService(e.target.value)}
                                                                            value={s.id}
                                                                            className='dp-flex-imp justify-content-between align-items-center result-select'>
                                                                            {s.name}
                                                                        </li>
                                                                    }
                                                                </ul>
                                                            )
                                                        })
                                                    }
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div >
                            {
                                service &&
                                <>
                                    <div className="row row-cols-xl-2">
                                        <div className='container'>
                                            <div className='bg-white mg-bottom_20 mg-top_20 default_dg_table bd-radius_10 box-shadow_1 default_dg_td'>
                                                <div className='pst-relative row align-items-center'>
                                                    <div className='pd-right_40'>
                                                        <div className='ft-size_12 color-red'>
                                                            {error && error.imei}
                                                        </div>
                                                        <input
                                                            name='imei'
                                                            onChange={(e) => handleChange(e)}
                                                            placeholder={service.placeholder ? service.placeholder : ''}
                                                            className="bg-f1f3f4 wd-100p bd-radius_10 pd-5_10 outline-none ft-size_15 bd-solid_dadce0"
                                                        />
                                                        <span className='dp-block pd-5_10 ft-size_12'>
                                                            {service.data_info ? service.data_info : ""}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <textarea
                                                            name='comments'
                                                            className='tx-area comm mg-bottom_20 pd-5_10 bd-radius_10 pd-5 outline-none bd-solid_dadce0'
                                                            placeholder={`${texts.Comments}:`}
                                                            onChange={(e) => handleChange(e)}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <div className="crt-order">
                                                        <button
                                                            disabled={options.loading}
                                                            onClick={() => _newImei()}
                                                            className='mg-top_20 bd-none bg-primary pd-5 bd-radius_10 cursor-pointer color-white'>
                                                            {
                                                                options.loading ?
                                                                    <div className='spin order' ></div>
                                                                    :
                                                                    <div>{texts.NewOrder}</div>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='container'>
                                            <div className='bg-white color-black mg-bottom_100 mg-top_20 default_dg_table bd-radius_10 box-shadow_1 default_dg_td'>
                                                <div className='cont-head'>
                                                   
                                                    {
                                                        service.time &&
                                                        <span className='ft-size_13'>{texts.DeliveryTime}: {service.time}</span>
                                                    }
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group ">
                                                        <div dangerouslySetInnerHTML={{ __html: service.description }} >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        </div >

    )
}

export default ImeiServices