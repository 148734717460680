import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    groups: {
        public: {
            groupImei: [],
            groupServe: []
        },
        private: {
            groupImei: [],
            groupServe: []
        }
    },

}

export const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        PrivateImei: (state, action) => {
            state.groups.private.groupImei = action.payload
        },
        PrivateServer: (state, action) => {
            state.groups.private.groupServe = action.payload
        },
        GroupImei: (state, action) => {
            state.groups.public.groupImei = action.payload
        },
        GroupServer: (state, action) => {
            state.groups.public.groupServe = action.payload
        }
    },
})

export const { PrivateImei, GroupImei, GroupServer, PrivateServer } = groupsSlice.actions

export default groupsSlice.reducer