import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnetApi';

export const get_services = (u) => {
    var url = u.page ? u.page : `${api}/services`;
    return axiosConfig.get(url, {
        params: {
            search: u.search
        },
    }).then(response => response);
}

export const get_service = (service) => {
    var url = `${api}/services`;
    return axiosConfig.get(`${url}/${service.id}`, {
    }).then(response => response);
}