import React, { useContext, useState } from 'react'
import axios from 'axios';
import { api } from '../../api/ConnetApi';
import { useNavigate } from 'react-router-dom';
import LanguageContext from '../../context/LanguageContext';
import { countries } from '../../constants/countries';

const Registerview = () => {

    const { texts } = useContext(LanguageContext);
    const [load, setLoad] = useState(false);
    const navigate = useNavigate();
    const [error, setErr] = useState([]);
    const [user, setUser] = useState({
        fullname: '',
        email: '',
        password: '',
        confirm: '',
    
      });

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
        setErr({
            ...error,
            [e.target.name]: ''
          })
    }

    const createUser = async () => {
        setLoad(true);
        if (user.password !== user.confirm) {
            return setErr(
              {confirm: ['The password does not match']}
            );
          }
        await axios.post(`${api}/register`, user, {
        })
            .then(res => {
                if(res.data.alert){
                    alert(res.data.alert);
                  }
                return navigate('/login');
            }).catch((err) => {
                setLoad(false);
                if (err.response.data) {
                    if(err.response.data.err){
                      return setErr(err.response.data.err);
                    }
                  }
            });
        setLoad(false);
    }

    return (
        <div className='container pst-relative top-20' >
            <div className='bg-white mg-left_auto mg-right_auto default_dg_table mg-bottom_100 bd-radius_10 box-shadow_1 default_dg_td '>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-9 col-xl-7 ">
                        <div className=' mg-bottom_20 mg-top_20'>
                            <span className="ft-size_25 ">{texts.Register}</span>
                        </div>
                        <form >
                            <div className="row">
                                <div className="col-md-6 mg-bottom_24">
                                    <div className="form-outline">
                                        <input
                                            onChange={(e) => handleChange(e)}
                                            name='fullname'
                                            className='wd-100p bd-solid_dadce0 outline-none hg-36 bd-radius_4 pd-6_8'
                                            placeholder={texts.FullName} />
                                    </div>
                                    <span className='tx-left ft-size_12 color-black position'>
                                        *{texts.InfoFullName}
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mg-bottom_24">
                                    <div className="form-outline">
                                        <input
                                            onChange={(e) => handleChange(e)}
                                            name='email'
                                            className='wd-100p bd-solid_dadce0 outline-none hg-36 bd-radius_4 pd-6_8'
                                            placeholder={texts.ExampleEmail} />
                                        <span className='tx-left ft-size_12 color-black position'>
                                            *{texts.InfoEmail}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mg-bottom_24">
                                    <div className="form-outline">
                                        <input
                                            onChange={(e) => handleChange(e)}
                                            name='password'
                                            autoComplete='new-password'
                                            type={'password'}
                                            className='wd-100p bd-solid_dadce0 outline-none hg-36 bd-radius_4 pd-6_8'
                                            placeholder={texts.ExamplePassword} />
                                        <span className='tx-left ft-size_12 color-black position'>
                                            {texts.InfoPassword}
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6 mg-bottom_24">
                                    <div className="form-outline">
                                        <input
                                            onChange={(e) => handleChange(e)}
                                            name='confirm'
                                            autoComplete='new-password'
                                            type={'password'}
                                            className='wd-100p  bd-solid_dadce0 outline-none hg-36 bd-radius_4 pd-6_8'
                                            placeholder={texts.Confirm} />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <button
                            onClick={() => createUser()}
                            className='bd-solid_dadce0 bg-primary color-white bd-radius_4 cursor-pointer pd-5 mg-bottom_24'>
                            {
                                load ?
                                    <div className='spin wd-45 login'></div>
                                    :
                                    <div>{texts.ButtonRegister}</div>

                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Registerview