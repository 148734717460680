import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    orders: {
        ordersImei: {
            data: []
        },
        ordersServe: {
            data: []
        }
    },
}

export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        OrdersImei: (state, action) => {
            state.orders.ordersImei = action.payload
        },
        OrdersServer: (state, action) => {
            state.orders.ordersServe = action.payload
        }
    },
})

export const { OrdersImei, OrdersServer } = ordersSlice.actions

export default ordersSlice.reducer