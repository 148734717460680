import React, { useContext } from "react";
import LanguageContext from "../../context/LanguageContext";

const ModalData = (props) => {
    const { texts } = useContext(LanguageContext);

    return (
        <>
            {
                props.isVisible == true &&
                <div className="container">
                    <div className="ebcf_modal ">
                        <div className="ebcf_modal-content">
                            <div className="color-white bg-primary bd-corners_top_11 tx-decoration_none ft-size_16 ft-weight_bold default_dg_a_header bd-bottom_solid_f1 mg-bottom_24">
                                {`${props.service.name}`}
                            </div>
                            <div className="mg-left_20 mg-right_20 bd-bottom_solid_f1 mg-bottom_24" dangerouslySetInnerHTML={{ __html: props.service.description }} ></div>
                            <div className="mg-bottom_24">
                                <span className="mg-left_20 ft-size_13 bg-gray pd-5 bd-radius_5 color-white">
                                    {
                                        props && props.service.time ?
                                            props.service.time
                                            :
                                            "No time specified"
                                    }
                                </span>
                            </div>
                            <div className="bd-bottom_solid_f1 mg-bottom_24">
                                <button className="ft-size_14 cursor-pointer mg-left_20 bd-solid_dadce0 bg-second color-white bd-radius_4 mg-bottom_24" onClick={props.onClose}>
                                    {texts.Close}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ModalData;