import React from 'react';
import { GrFormSearch } from 'react-icons/gr';


const Search = (props) => {
    const { action, place } = props


    return (
        <div className='srch justify-content-center dp-flex align-items-center mg-top_20 mg-bottom_20 pst-relative' >
            <GrFormSearch className='bd-corners_left_10  bd-solid_dadce0 bd-right_none hg-23' />
            <input
                onChange={(e) => action(e.target.value)}
                placeholder={place}
                className='wd-50p ft-size_12 hg-23 bd-left_none bd-solid_dadce0 outline-none'
            />
        </div>
    )
}

export default Search