import React, { useEffect, useRef, useState } from 'react';
import { BiChevronLeft, BiChevronRight, BiFirstPage, BiLastPage } from 'react-icons/bi';


const Paginator = (props) => {
    let navigate = props && props.data && props.data;
    const disabled = 'disabled'
    const [perpage, setPerpage] = useState('')

    const test = useRef();
    const test2 = useRef();


    useEffect(() => {
        if (navigate) {
            setPerpage(props.data.per_page);
        }
    }, [props]);

    const changePerPage = (e) => {
        setPerpage(e)
        props.action({ per_page: e })
    }

    const active = [
        {
            per_page: 10,
        },
        {
            per_page: 20,
        },
        {
            per_page: 50,
        },
        {
            per_page: 100,
        },
    ];

    return (
        <div className='paginate dp-flex' id='op' ref={test2}>
            <div className='num-cant'>
                <span>
                    {navigate.from}
                </span>
                <span className='pd-left_5'>
                    to
                </span>
                <span className='pd-left_5'>
                    {navigate.to}
                </span>
                <span>
                    /{navigate.total}
                </span>
            </div>
            <div className='btn-pag'>
                <BiFirstPage
                    disabled={navigate.prev_page_url ? false : true}
                    onClick={() => props.action({ page: navigate.first_page_url, per_page: perpage })}
                    className={navigate.prev_page_url ? `cursor-pointer box-shadow_paginate bg-f1f3f4 mg-0_10` : `mg-0_10 opacity-05 bg-f1f3f4 box-shadow_paginate_dis`}
                />
                <BiChevronLeft
                    disabled={navigate.prev_page_url ? false : true}
                    onClick={() => props.action({ page: navigate.prev_page_url, per_page: perpage })}
                    className={navigate.prev_page_url ? `cursor-pointer box-shadow_paginate bg-f1f3f4 mg-0_10` : `mg-0_10 box-shadow_paginate_dis bg-f1f3f4 opacity-05`}
                />
                <span className={`ft-size_12 box-shadow_paginate mg-0_10 bg-f1f3f4 pd-left_5 pd-right_5 select-none`}>
                    {navigate.current_page}
                </span>
                <BiChevronRight
                    disabled={navigate.next_page_url ? false : true}
                    onClick={() => props.action({ page: navigate.next_page_url, per_page: perpage })}
                    className={navigate.next_page_url ? `box-shadow_paginate bg-f1f3f4 cursor-pointer mg-0_10` : `mg-0_10 box-shadow_paginate_dis bg-f1f3f4 opacity-05`}
                />
                <BiLastPage
                    disabled={navigate.next_page_url ? false : true}
                    onClick={() => props.action({ page: navigate.last_page_url, per_page: perpage })}
                    className={navigate.next_page_url ? `box-shadow_paginate bg-f1f3f4 cursor-pointer mg-0_10` : `mg-0_10 box-shadow_paginate_dis bg-f1f3f4 opacity-05`}
                />
            </div>
            <div className='page-current' ref={test}>
                <select
                    onChange={(e) => changePerPage(e.target.value)}
                    value={navigate.per_page}
                >
                    {
                        active.map((a, k) => {
                            return (
                                <option key={k} value={a.per_page}>
                                    {a.per_page}
                                </option>
                            )
                        })
                    }
                </select>
            </div>
        </div>
    )
}

export default Paginator