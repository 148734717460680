import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnetApi';

export const get_orders = (u) => {
    var url = u.page ? u.page : `${api}/orders`;
    return axiosConfig.get(url, {
        params: {
            action: u.action,
            search: u.search,
            per_page: u.per_page
        },
    }).then(response => response);
}

export const get_order = (order) => {
    var url = `${api}/orders`;
    var action = order.action;
    return axiosConfig.get(`${url}/${order.id}`, {
        params: {
            action: action
        },
    }).then(response => response);
}

export const new_order = (order) => {
    var url = `${api}/orders`;
    var action = order.action;
    return axiosConfig.post(`${url}`, order, {
        params: {
            action: action
        },
    }).then(response => response);
}