import React, {useContext} from 'react';
import LogoSvg from '../../assets/svg/Logo_fi.svg';
import LogoDatabase from '../../assets/svg/Database.svg';
import LogoStart from '../../assets/svg/Start.svg';
import LanguageContext from '../../context/LanguageContext';

const Homefirst = () => {
    const { texts } = useContext(LanguageContext);

    return (
        <div className='dp-block wd-100p'>
            <div class="background-sky sky"></div>
            <section id='intro'>
            <div class="sky-logo">
                <img src={LogoSvg}/>
            </div>
            </section>
            <div className='cont-center home-row'>
                <div className='max-wd_50p pd-30'>
                    <img className='img-atri' src={LogoStart}/>
                </div>
                <div className='max-wd_50p'>
                    <h2 class="text-headline no-anchor">{texts.firstTitle}</h2>
                    <p class="text-body">
                    {texts.firstSubtitle}
                    </p>
                </div>
            </div>
            <div className='cont-center home-row'>
                <div className='max-wd_50p pd-30'>
                    <h2 class="text-headline no-anchor">{texts.secondTitle}</h2>
                    <p class="text-body">
                    {texts.secondSubtitle}
                    </p>
                </div>
                <div className='max-wd_50p'>
                    <img className='img-atri' src={LogoDatabase}/>
                </div>
            </div>
        </div >
    )
}

export default Homefirst