import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store/Store';
import AppRoute from './routes/AppRoute';
import { CookiesProvider } from 'react-cookie';
import { LanguageProvider } from './context/LanguageContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <CookiesProvider>
      <BrowserRouter>
        <LanguageProvider>
          <AppRoute />
        </LanguageProvider>
      </BrowserRouter>
    </CookiesProvider>
  </Provider>
);
