import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    subs: {
        data: []
    },
}

export const subsSlice = createSlice({
    name: 'subs',
    initialState,
    reducers: {
        allSubs: (state, action) => {
                state.subs = action.payload
        },
        sumSubs: (state, action) => {
            let sum = [].concat(state.subs.data, action.payload.data);
            state.subs = action.payload
            state.subs.data = sum 
        }
    },
})

export const { allSubs, sumSubs } = subsSlice.actions

export default subsSlice.reducer