import React, { useContext } from 'react';
import moment from 'moment';
import LanguageContext from '../../context/LanguageContext';

const Modalorders = (props) => {
    const {order, onClose} = props;
    const { texts } = useContext(LanguageContext);

    return (
        <>
            {
                props.isVisible == true &&
                <div className="container">
                    <div className="ebcf_modal ">
                        <div className='wd-600 ebcf_modal-content bg_modal'>
                            {
                                order.response &&
                                <div className="tx-center color-lavel ft-family-pre pd-30" dangerouslySetInnerHTML={{ __html: order.response }} ></div>
                            }
                            <div className='table-add dp-flex justify-content-between'>
                                <button
                                    onClick={onClose}
                                    className='ft-size_12 bd-none bg-primary pd-8 bd-radius_10 cursor-pointer color-white'
                                >
                                    {texts.Close}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>     
            }
        </>
    )
}

export default Modalorders