import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AllTickets } from '../../../store/storeReducers/TicketsReducer';
import { OrderStatus } from '../../../components/ImagenStatus';
import moment from 'moment';
import Paginator from '../../../components/Paginator';
import { useOutletContext } from 'react-router-dom';
import { get_tickets } from '../../../functions/BillsFunction';
import { update_Spin } from '../../../store/storeReducers/LoadingReducer';
import LanguageContext from '../../../context/LanguageContext';
import { get_subs } from '../../../functions/SubsFunction';
import { allSubs } from '../../../store/storeReducers/SubsReducer';


const SubsUser = () => {

    const { texts } = useContext(LanguageContext);
    const dispatch = useDispatch();
    const param = useOutletContext();
    const spin = useSelector((state) => state.loading.loading.spin);
    const getSubs = useSelector((state) => state.subs.subs);


    useEffect(() => {
         getSubscription();
        dispatch(update_Spin(true));
    }, [param.count])

    const  getSubscription = async (value) => {
        try {
            const sub = {
                per_page: value && value.per_page ? value.per_page : 20,
                page: value && value.page ? value.page : '',
            }
            const res = await get_subs(sub);
            dispatch(allSubs(res.data.subscriptions));
        } catch (error) {
        }
        dispatch(update_Spin(false));
    }

    return (
        <div className='profile-content mg-bottom_100'>
            {
                !spin &&
                <div className='container'>
                    <div className='mg-left_auto bg-white mg-right_auto mg-bottom_100 mg-top_20  bd-radius_10 box-shadow_1 pd-10 '>
                        <div className='tx-center'>
                            <span className="ft-size_25">{texts.Subscriptions}</span>
                        </div>
                        <div className="justify-content-center align-items-center table-responsive">
                            <table className='mg-left_auto mg-right_auto default_dg_table mg-bottom_20'>
                                <thead >
                                    <tr >
                                        <th className="default_dg_td tx-left default_dg_a_header col-md-1">#</th>
                                        <th className="default_dg_td tx-center default_dg_a_header ">{texts.Time}</th>
                                        <th className="default_dg_td tx-center default_dg_a_header">{texts.Start}</th>
                                        <th className="default_dg_td tx-center default_dg_a_header">{texts.End}</th>
                                    </tr>
                                </thead>
                                {
                                    getSubs.data.map((t, k) => {
                                        const status = OrderStatus({ status: t.status, text: texts });
                                        return (
                                            <tbody key={k}>
                                                <tr className="dg_tr cursor-pointer ft-family_monospace">
                                                    <td className="default_dg_td tx-left dg_nowrap bd-lright_solid1_f1 white_space">#{k + 1}</td>
                                                    <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-lright_solid1_f1 white_space">{t.time}</td>
                                                    <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-lright_solid1_f1 white_space">{t.start}</td>
                                                    <td className="default_dg_td tx-center dg_nowrap bd-left_solid1_f1 bd-lright_solid1_f1 white_space">{t.end}</td>
                                                </tr>
                                            </tbody>
                                        )
                                    })
                                }
                            </table>
                            <Paginator
                                data={getSubs}
                                action={(t) =>  getSubscription(t)}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default SubsUser