import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    services: {
        services: []
    },
    totalService:{
        services:[]
    }
}

export const servicesSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {
        getServices: (state, action) => {
                state.services.services = action.payload
        },
        totalServices: (state, action) => {
            state.totalService.services = action.payload
         }
    },
})

export const { getServices , totalServices} = servicesSlice.actions

export default servicesSlice.reducer