import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnetApi';

export const get_user = () => {
    var url = `${api}/profile`;
    return axiosConfig.get(`${url}`, {
    }).then(response => response);        
}

export const update_user = (u) => {
    var url = `${api}/update_profile`;
    return axiosConfig.post(`${url}`, u.data, {
    }).then(response => response);
}

export const logout = (u) => {
    var url = `${api}/logout`;
    return axiosConfig.get(`${url}`, {
    }).then(response => response);
}