import { createContext, useState } from "react";
import { en } from "../languages/en";
import { es } from "../languages/es";
import Cookies from 'universal-cookie';


const LanguageContext = createContext();
const cookies = new Cookies();

const initialLanguage = cookies && cookies.get('lang') ? cookies.get('lang') : 'en';
const translations = {
    en,
    es
}

const LanguageProvider = ({ children }) => {
    const [texts, setTexts] = useState(translations[initialLanguage]);

    const handleLanguage = (e) => {
        switch (e.target.value) {
            case 'en':
                cookies.set('lang', e.target.value, { path: '/' });
                break;
            case 'es':
                cookies.set('lang', e.target.value, { path: '/' });
                break;
        }
        window.location.reload();
    }

    const data = { texts, handleLanguage, initialLanguage }

    return (
        <LanguageContext.Provider value={data}>
            {children}
        </LanguageContext.Provider>
    )
}

export { LanguageProvider }




export default LanguageContext;