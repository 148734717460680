import axiosConfig from '../axiosConfig';
import { api } from '../api/ConnetApi';

export const get_subs = (u) => {
    var url = u.page ? u.page : `${api}/subscriptions`;
    return axiosConfig.get(url, {
        params: {
            per_page: u.per_page
        },
    }).then(response => response);
}