import React, { useContext } from 'react';
import LanguageContext from '../../context/LanguageContext';

const Modalbanks = (props) => {
    const { texts } = useContext(LanguageContext);

    return (
        <>
            {
                props.isVisible == true &&
                <div className="container">
                    <div className="ebcf_modal ">
                        <div className="wd-600 ebcw_modal-content ">
                            <div className="color-1B74E4 justify-content-between align-items-center dp-flex tx-decoration_none ft-size_16 ft-weight_bold default_dg_a_header bd-bottom_solid_f1 mg-bottom_24">
                                <div className='pd-5'>
                                    {`${props.bank.name}`}
                                </div>
                                <span className="ft-size_13 bg-gray pd-5 bd-radius_5 color-white">
                                    {`${props.bank.holder_name}`}
                                </span>
                            </div>
                            <div className='pd-10'>
                                {`${props.bank.num_account}`}
                            </div>
                            <div className="pd-20 bd-bottom_solid_f1 mg-bottom_24" dangerouslySetInnerHTML={{ __html: props.bank.description }} ></div>
                            <div className="bd-bottom_solid_f1 mg-bottom_24">
                                <button className="cursor-pointer mg-left_20 bd-solid_dadce0 bg-gray color-white bd-radius_4 mg-bottom_24" onClick={props.onClose}>
                                    {texts.Close}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Modalbanks