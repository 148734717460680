import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    tickets: {
        data: []
    },
}

export const ticketsSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {
        AllTickets: (state, action) => {
            state.tickets = action.payload
        }
    },
})

export const { AllTickets } = ticketsSlice.actions

export default ticketsSlice.reducer