export const OrderStatus = (v) => {
    const status = parseFloat(v.status);
    const texts = v.text;

    if (status === 0) {
        var imagen = <div className='new-order' > {texts.Waiting}</div>
    }
    else if (status === 1) {
        var imagen = <div className='proccess-order' > {texts.Proccess}</div>
    }
    else if (status === 3) {
        var imagen = <div className='reject-order' > {texts.Reject}</div>
    }
    else if (status === 4) {
        var imagen = <div className='success-order' > {texts.Success}</div>
    }

    return imagen;
}