import React from 'react'
import { useSelector } from 'react-redux';

const Spinning = () => {

    const spin = useSelector((state) => state.loading.loading.spin);

    return (

        <div>
            {spin && <div className='spinn' />}
        </div>

    )
}

export default Spinning