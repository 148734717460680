import React, { useContext, useState } from 'react';
import LanguageContext from '../context/LanguageContext';

const Footer = () => {
    const { texts, handleLanguage, initialLanguage } = useContext(LanguageContext);
    const [load, setLoad] = useState(false);

    const handleMessage = () => {
        setLoad(true);
        setTimeout(() => {
            alert('Message sended successfully');
            setLoad(false);
        }, [3000])
    }

    return (
        <div className='footer bg-navbar'>
            <footer className="bg_black text-center text-white">
                <div className="container p-4">
                    <section className="">
                        <div className="row">
                            <div className="col-md-5 menu">
                                <h4 className="text-uppercase">{texts.Menu}</h4>
                                <div className='justify-content-between mg-10 pd-10 dp-flex flex-wrap'>
                                    <div className='ft-size_14 cursor-pointer'>
                                        <span className='bd-bottom_1'>{texts.AboutUs}</span>
                                    </div>
                                    <div className='ft-size_14 cursor-pointer'>
                                        <span className='bd-bottom_1'>{texts.Contacts}</span>
                                    </div>
                                    <div className='ft-size_14 cursor-pointer'>
                                        <span className='bd-bottom_1'>{texts.TermsCondition}</span>
                                    </div>
                                    <div className='ft-size_14 cursor-pointer'>
                                        <span className='bd-bottom_1'>{texts.PrivacyPolicy}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-5 lang'>
                                <h4>{texts.Language} </h4>
                                <div className='dp-flex itm-center mg-10 pd-10 justify-content-evenly'>
                                    <select className='lg-chng outline-none ft-size_15 bd-solid_dadce0'
                                        onChange={handleLanguage}
                                        defaultValue={initialLanguage}
                                    >
                                        <option value='en'>Ingles</option>
                                        <option value='es'>Español</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-5 contact">
                                <h4 className="text-uppercase">{texts.ConstactUs}</h4>
                                <div className='dp-flex itm-center mg-10 pd-10 justify-content-evenly'>
                                    <input
                                        placeholder={`${texts.YourEmail}:`}
                                        className="bg-f1f3f4 bd-radius_10 pd-5_10 outline-none ft-size_15 bd-solid_dadce0"
                                    />
                                    <textarea
                                        placeholder={`${texts.YourMessage}:`}
                                        className='hg-46 wd-320 pd-5_10 bd-radius_10 pd-5 outline-none bd-solid_dadce0'
                                    >
                                    </textarea>
                                    <button
                                        onClick={() => handleMessage()}
                                        className='bg-second bd-none cursor-pointer color-white bd-radius_4 pd-5'>
                                        {
                                            load ?
                                                <div className='spin login wd-45'></div>
                                                :
                                                <div className='wd-45'>{texts.Send}</div>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </footer>
        </div>
    )
}

export default Footer